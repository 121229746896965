import * as React from "react";
import PlaylistPreview from "../images/playlist-cleaner-snapshot.png";
import "../styles/projects.css"
const ProjectsSection = () => {

    return (
        <section className="resume-section" id="projects">

            <div className="resume-section-content">
                <h2 className="mb-5">Projects</h2>
                <div className="row projects-row">
                    <div className="col" style={{maxWidth: '25rem'}}>
                        <div className="card">
                            <img className="card-img-top" src={PlaylistPreview} alt="Card image cap"/>
                                <div className="card-body">
                                    <h5 className="card-title">Spotify Playlist Cleaner</h5>
                                    <p className="card-text">Web app that allows you to convert a Spotify playlist into a completely non-explicit playlist. More music streaming services coming soon!</p>
                                    <a href="https://playlistcleaner.com" className="text-white btn btn-primary">Check it out!</a>
                                </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ProjectsSection