import React from "react"
import Headshot from "../images/headshot.jpeg";
import {useInView} from "react-hook-inview";
import Seo from "./seo";

const AboutSection = () => {

    return (
        <section className="resume-section" id="about" style={{ flexDirection: "column"}}>

            <img style={{ maxWidth: '30%', }}
                    className="d-none d-sm-block d-md-block d-lg-none img-fluid img-profile rounded-circle mx-auto mb-5" src={Headshot}
                    alt="..."/>
            <img style={{ maxWidth: '50%', }}
                 className="d-block d-sm-none img-fluid img-profile rounded-circle mx-auto mb-5" src={Headshot}
                 alt="..."/>
            {/* About Me Section of website */}
            <div className="resume-section-content">
                <h1 className="mb-0">
                    Sean&nbsp;
                    <span className="text-primary">Hoerl</span>
                </h1>
                <div className="subheading mb-5">
                    Boston, MA 02120 · (978) 806-1472 ·&nbsp;
                    <a href="mailto:sean.hoerl@gmail.com">hoerl.s@northeastern.edu</a>
                </div>
                <p className="lead mb-5">
                    I am a fourth year student at Northeastern University studying Computer Science.
                    I have experience doing Full Stack Web Development and Application Development.
                    I am experienced in leveraging agile frameworks to provide robust and portable solutions for high level problems.</p>
                <div className="social-icons">
                    <a className="social-icon" href="https://www.linkedin.com/in/sean-hoerl-372a5617b/"><i className="fab fa-linkedin-in"></i></a>
                    <a className="social-icon" href="https://github.com/shoerl"><i className="fab fa-github"></i></a>
                    <a className="social-icon" href="resume.pdf"><i className="fas fa-download"></i></a>
                </div>
            </div>
        </section>
    )
}

export default AboutSection
