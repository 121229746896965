import * as React from "react"
import '../styles/styles.css'
import NavBarComponent from "../components/navbar";
import AboutSection from "../components/about";
import ExperienceSection from "../components/experience";
import EducationSection from "../components/education";
import SkillsSection from "../components/skills";
import Seo from "../components/seo";
import ProjectsSection from "../components/projects";

// markup
const IndexPage = () => {

  return (
    <main>
      <div>
        <Seo/>
        <NavBarComponent/>
        <div className="container-fluid p-0">
          <AboutSection/>
          <hr className="m-0"/>
          <ExperienceSection/>
          <hr className="m-0"/>
          <EducationSection/>
          <hr className="m-0"/>
          <ProjectsSection/>
          <hr className="m-0"/>
          <SkillsSection/>
        </div>

      </div>
    </main>
  )
}

export default IndexPage
