import * as React from "react";
import {useInView, useInViewEffect} from 'react-hook-inview'
import Seo from "./seo";

const ExperienceSection = () => {

    return (
        <section className="resume-section" id="experience">
            <div className="resume-section-content">
                <h2 className="mb-5">Experience</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Software Engineer</h3>
                        <h4 className="mb-0">Co-op</h4>
                        <div className="subheading mb-3">Mercury Systems</div>
                        <ul>
                            <li>Leveraged Ruby and Jenkins to build out a framework and application that allows for autonomous testing of IPMI functionality on computer boards.</li>
                            <li>Implemented features in the IPMI firmware using C.</li>
                            <li>Maintained rigorous coding standards in an effort to write clear, self documenting code.</li>
                            <li>Participated in the AGILE and SCRUM processes. Assisted as a SCRUM Master.</li>
                        </ul>

                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">July 2021 - December 2021</span>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Full Stack Web Developer</h3>
                        <h4 className="mb-0">Part Time</h4>
                        <div className="subheading mb-3">Clinical Research IO</div>
                        <ul>
                            <li>Increased secure logins by implementing Two Factor Authentication workflows and UI with Google Firebase.</li>
                            <li>De-coupled user-uploaded static images from the source repository.</li>
                            <li>Built an internal tool to upload static images to a public directory that could be served.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span
                        className="text-primary">January 2021 - May 2021</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Full Stack Web Developer</h3>
                        <h4 className="mb-0">Co-op</h4>
                        <div className="subheading mb-3">Clinical Research IO</div>
                        <ul>
                            <li>Implemented both the front and back end functionality for billing unscheduled visits in the company's financial module, which is utilized by over 600 clinical research sites.</li>
                            <li>Maintained and refactored live search using Solr and SolrJ for use in their clinical trial recruitment module.</li>
                            <li>Redesigned a Regulatory Delegation Log algorithm for use in their electronic regulatory module.</li>
                            <li>Identified and fixed various types of bugs on a daily basis using Java, Javascript, and SQL.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span
                        className="text-primary">July 2020 - December 2020</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Computer Science Tutor and Teaching Assistant</h3>
                        <h4 className="mb-0">Part Time</h4>
                        <div className="subheading mb-3">Northeastern University</div>
                        <ul>
                            <li>Tutored students in Fundamentals of Computer Science 2 in both traditional and accelerated model.</li>
                            <li>Taught students concepts such as Polymorphism, Mutation, Recursion, Equality, and Abstraction.</li>
                            <li>Hosted weekly office hours to transfer knowledge, motivate, and assist with debugging and assignment planning.</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0"><span
                        className="text-primary">September 2019 - April 2020</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Software Engineer</h3>
                        <h4 className="mb-0">Internship</h4>
                        <div className="subheading mb-3">Mercury Systems</div>
                        <ul>
                            <li>Eradicated bugs in legacy software using Bash, Python, and Ruby.</li>
                            <li>Automated nightly testing for computer boards saving a large team of employees over 25 hours each week.</li>
                            <li>Started migration of company source control from ClearCase to Git.</li>
                        </ul>

                    </div>
                    <div className="flex-shrink-0"><span
                        className="text-primary">June 2019 - September 2019</span></div>
                </div>
            </div>
        </section>
    )
}

export default ExperienceSection
