import * as React from "react";
import {useInView} from "react-hook-inview";
import Seo from "./seo";

const SkillsSection = () => {

    return (
        <section className="resume-section" id="skills">

            <div className="resume-section-content">
                <h2 className="mb-5">Skills</h2>
                <div className="subheading mb-3">Programming Languages</div>
                <ul className="list-inline dev-icons">
                    <li className="list-inline-item"><i className="fab fa-java"></i></li>
                    <li className="list-inline-item"><i className="devicon-ruby-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="fab fa-python"></i></li>
                    <li className="list-inline-item"><i className="fab fa-js-square"></i></li>
                    <li className="list-inline-item"><i className="fab fa-html5"></i></li>
                    <li className="list-inline-item"><i className="fab fa-css3-alt"></i></li>
                    <li className="list-inline-item"><i className="devicon-c-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-bash-plain svg-inline--fa"></i></li>
                </ul>
                <div className="subheading mb-3">Frameworks & Platforms</div>
                <ul className="list-inline dev-icons">
                    <li className="list-inline-item"><i className="devicon-react-original svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-bootstrap-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-jquery-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-firebase-plain svg-inline--fa"></i></li>

                </ul>
                <div className="subheading mb-3">Software & Applications</div>
                <ul className="list-inline dev-icons">
                    <li className="list-inline-item"><i className="devicon-git-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-intellij-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-mysql-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-vim-plain svg-inline--fa"></i></li>
                    <li className="list-inline-item"><i className="devicon-docker-plain svg-inline--fa"></i></li>


                </ul>
                <div className="subheading mb-3">Workflow</div>
                <ul className="fa-ul mb-0">

                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Cross Functional Teams
                    </li>
                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Agile Development & Scrum
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default SkillsSection