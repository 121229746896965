import * as React from "react";
import {useInView} from "react-hook-inview";
import Seo from "./seo";

const EducationSection = () => {

    return (
        <section className="resume-section" id="education">
            <div className="resume-section-content">
                <h2 className="mb-5">Education</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">Northeastern University</h3>
                        <h4 className="mb-0">Khoury College of Computer Science</h4>
                        <div className="subheading mb-3">Candidate for Bachelor of Science in Computer Science</div>
                        <div>GPA: 3.72/4.0</div>
                        <p>Dean's List</p>
                        <h5 className="mb-2">Relevant Classes</h5>
                        <ul className="mb-3">
                            <li>Database Design</li>
                            <li>Theory of Computation</li>
                            <li>Networks & Distributed Systems</li>
                            <li>Probability & Statistics</li>
                            <li>Computer Systems</li>
                            <li>Object Oriented Design</li>
                            <li>Web Development</li>
                            <li>Algorithms & Data</li>
                            <li>Logic & Computation</li>
                            <li>Fundamentals of Computer Science 1 & 2</li>
                            <li>Calculus 1 & 2</li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0">
                        <span className="text-primary">September 2018 - December 2022</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EducationSection
