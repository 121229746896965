import Headshot from "../images/headshot.jpeg";
import * as React from "react";

const NavBarComponent = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a className="navbar-brand js-scroll-trigger" href="#top">
                <span className="d-block d-lg-none">Sean Hoerl</span>
                <span className="d-none d-lg-block"><img
                    className="img-fluid img-profile rounded-circle mx-auto mb-2" src={Headshot}
                    alt="..."/></span>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link js-scroll-trigger"
                                                href="#about">About</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger"
                                                href="#experience">Experience</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger"
                                                href="#education">Education</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger"
                                                href="#projects">Projects</a></li>
                    <li className="nav-item"><a className="nav-link js-scroll-trigger"
                                                href="#skills">Skills</a>
                    </li>

                </ul>
            </div>
        </nav>

    )
}

export default NavBarComponent
